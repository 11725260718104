.toastMsg {
    font-size: 20px;
    text-align: left;
    button {
        height: 50px;
        width: 50px;
        border: none;
        margin: 0;
        background: none;
    }
}