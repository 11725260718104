$navFontSize : 20px;
$linkColor: #222;
$backgroundColor: #fdfdfd;

nav {
    font-size: $navFontSize;
    background-color: $backgroundColor;
    display: flex;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    margin-left: 30px;
    margin-right: 10px;
    h1 {
        font-weight: normal;
        font-size: $navFontSize
    }
    .burger_memu {
        margin-right: 10px;
        z-index: 1;
        width: 60px;
        height: 60px;
        background: none;
    }
    @keyframes memuToggle {
        0% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
        from {
            top: 55px
        }
        to {
            top: 60px
        }
    }
    button {
        border: none;
        font-size: 20px;
        cursor: pointer;
        img {
            height: 30px;
            width: 30px;
        }
    }
    button_rotate {
        transform: rotate(45deg)
    }
    ul {
        @media (max-width: 800px) {
            position: absolute;
            width: 100vw;
            left:0;
            top: 60px

        }
    }
    ul li {
        display: inline-block;
        list-style: none;
        padding-right: 20px;
        @media (max-width: 800px) {
            display: block;
            padding: 15px 0;
            background: #fdfdfd;
            text-align: center;
        }
    }
    a {
        text-decoration: none;
        color: $linkColor
    }
    
}