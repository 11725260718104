.landing {
    display: block;
    .intro {
        text-align: center
    }
    .photosWall {
        /* Prevent vertical gaps */
        // display: inline-block;
        line-height: 0;
        column-count: 6;
        column-gap: 0;
        .photo {
            width:100%;
            img {
                /* in case there are inline attributes */
                width: 100% !important;
                height: auto !important;
            }
        }
        
        // @media (max-width: 1200px) {
        //     column-count: 4;
        // }
        @media (max-width: 1000px) {
            column-count: 3;
        }
        // @media (max-width: 800px) {
        //     column-count: 2;
        // }
        // @media (max-width: 400px) {
        //     column-count: 1;
        // }
    }
    .intro {
        display: flex;
        padding: 15px;
        height: 65vh;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2 {
            font-size: 24px;
        }
        .hr_line {
            width: 80px;
            border-top: 2px solid #222;
            margin: 20px
        }
        .quote {
            font-size: 18px
        }
        .quote_by {
            text-align: right;
            display: inherit;
            margin: 20px 0
        }
    }
}