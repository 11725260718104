.loginForm, .register, .profile {
    // text-align: center;
    legend {
        text-align: center;
        font-size: 24px;
        margin: 30px auto;
    }
    label {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 20px;
        width: 110px;
    }
    input {
        // display: block;
        // line-height: 3em;
        font-size: 24px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0px;
        border-bottom: 1px solid black;
    }
    
    button {
        display: block;
        cursor: pointer;
        margin: 20px auto;
        height: 100px;
        width: 100px;
        font-size: 22px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 255, 0.2);
        background: none
    }
    button:hover {
        animation: hoverMe 1s infinite;
    }
    @import "./toastMsg";

    @keyframes hoverMe {
        0% {
            border: 1px solid rgba(255, 0, 0, 0.7);
        }
        30% {
            border: 1px solid rgba(255, 0, 0, 0.3);
        }
        75% {
            border: 1px solid rgba(255, 0, 0, 0.5);
          }
        100% {
            border: 1px solid rgba(255, 0, 0, 0.2);
        }
    }
}