.timeline {
    // width: 60vw;
    margin: auto;
    ul {
        li {
            width: 100vw;
            list-style: none;
            display: flex;
            font-size: 0;
            .moment {
                flex: 1;
                .text, .date{
                    display: block;
                    text-align: center;
                }
                margin: auto;
                font-size: 24px;
            }
            .image {
                flex: 1;
                line-height: 0;
                column-count: 2;
                column-gap: 0px;
                width: 100%;
                img {
                    width: 100% !important
                }
                a {
                    display: inherit
                }
            }
            &:nth-child(odd) {
                background: gray;
                flex-direction: row-reverse;
            }
            &:nth-child(even) {
                background: red;
            }
            @media (max-width: 800px) {
                display: inline-block;
                width: 100vw;
                .image {
                    img {
                        max-width: 100vw;
                        max-height: unset
                    }
                }
            }
        }
    }
}